import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

import Layout from '~components/Layout'
import Main from '~components/layouts/Main'
import HeroSection from '~components/layouts/marketing/HeroSection'
import SEO from '~components/seo'

const Button = styled(Link)`
  font-size: 14px !important;
  width: 100% !important;
`

const CustomerServicesPage = ({ data: { markdownRemark } }) => {
  const {
    html,
    frontmatter: {
      title,
      description,
      lead,
      path,
      src,
      srcMobile
    }
  } = markdownRemark
  return (
    <Layout>
      <SEO title={title} description={description} pathname={path} />
      <Main className="mb-5">
        <HeroSection
          title={title}
          fluid={false}
          breadcrumb={{ title: 'At Your Service', path: '/customer-services' }}
          mobileImg={srcMobile}
          desktopImg={src}
        >
          <>
            <h2 className="mb-3">{lead}</h2>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <Row className="mt-4">
              <Col lg={6}>
                <Button
                  to="/start-a-repair"
                  className="btn btn-primary mb-2 mb-lg-0 mr-1"
                >
                  Start a Repair
                </Button>
              </Col>
              <Col lg={6}>
                <Button to="/estimates" className="btn btn-outline-primary">
                  View My Estimates
                </Button>
              </Col>
            </Row>
          </>
        </HeroSection>
      </Main>
    </Layout>
  )
}

CustomerServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        lead: PropTypes.string,
        path: PropTypes.string,
        src: PropTypes.string,
        srcMobile: PropTypes.shape({
          publicUrl: PropTypes.string
        })
      })
    })
  })
}

export default CustomerServicesPage

export const customerServicePageQuery = graphql`
  query CustomerServicesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        lead
        path
        src
        srcMobile
      }
    }
  }
`
